<template>
    <div class="home">
      <div class="pt20"></div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="账号状态">
            <el-select v-model="formInline.status" placeholder="状态">
                <el-option label="全部" value="0"></el-option>
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input style="width:300px;" v-model="formInline.query" placeholder="用户编号、账号、姓名、手机号"></el-input>
            <el-button style="margin-left:20px;" type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div class="pt20"></div>
      <div class="left">
        <el-button @click="goNav('/user/useradd')" v-if="add == 1" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
        <el-button @click="menuChangeStatus(3)" v-if="dele == 1" type="danger" style="font-size: 16px;font-weight: bold;" plain>删除</el-button>
        <el-button @click="menuChangeStatus(1)" v-if="dele == 1" type="success" style="font-size: 16px;font-weight: bold;" plain>启用</el-button>
        <el-button @click="menuChangeStatus(2)" v-if="dele == 1" type="warning" style="font-size: 16px;font-weight: bold;" plain>禁用</el-button>
      </div>

      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
        
        <el-table-column
          prop="user_id" 
          label="用户编号">
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="account"
          label="账号"
          width="180">
        </el-table-column>

        <el-table-column
          prop="status_text"
          label="状态">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button @click="goedit(scope.row)" v-if="edit == 1" size="mini" type="info" plain>编辑</el-button>
        <el-button @click="goedit2(scope.row)" v-if="detail == 1" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
       
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
//import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      add:location.add,
      dele:location.delete,
      edit:location.edit,
      detail:location.detail,
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
        query:'',
        status:'0',
        page:1,
      },
      initdata: {
        query:'',
        status:'0',
        page:1,
      },
      timevalue:'',
      // 选中数组
      ghs: [],
     //选中的记录数量
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
      onSubmit(CHA){
        let _this = this;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          // _this.formInline.sdate = '';
          // _this.formInline.edate = '';
          // if(this.timevalue){
          //   this.formInline.sdate = formatDate(this.timevalue[0]);
          //   this.formInline.edate = formatDate(this.timevalue[1]);
          // }
        _this.loading = true
        axios.get(config.user,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
          _this.loading = false
        });
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goNav(url){
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
        this.$router.push(url);
      },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      //  this.$router.push('/user/useredit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/user/useredit');
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      //  this.$router.push('/user/useredit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/user/useredit');
      },
      menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        if(this.selectedNum != 1){
          this.$message.error('不支持批量');
          return;
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.userChangeStatus, {user_id:this.ghs[0],status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.user_id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
        console.log(this.ghs);
        console.log(this.selectedNum);
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.user,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
          _this.loading = false
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
</style>